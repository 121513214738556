import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import store from './store'
import VueI18n from 'vue-i18n'
import Cookie_Law from 'vue-cookie-law'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import LoadScript from 'vue-plugin-load-script';
import JsonExcel from "vue-json-excel";
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faFacebookF, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons'


Vue.use(VueI18n);
Vue.use(Vuex);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(require('vue-moment'));
Vue.use(LoadScript);
Vue.component("cookie-law", Cookie_Law);
Vue.component("downloadExcel", JsonExcel);
library.add(faInstagram)
library.add(faFacebookF)
library.add(faYoutube)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Ready translated locale messages
const messages = {
    en: {
        nav: {
            myAccount: 'MY ACCOUNT',
            logout: 'LOGOUT',
            boxTypes: 'BOX-TYPES',
            transactions: 'TRANSACTIONS',
            myOrders: 'MY ORDERS',
            register: 'REGISTER',
            logIn: 'LOGIN'
        },
        home: {
            getPvrKey: 'GET PVR KEY',
            q20: 'For viewing free and coded television programs on the Tivùsat satellite platform and all free-to-air satellite channels (hotbird, astra, etc.). Ideal solution for all those who live in areas difficult to reach by the digital terrestrial television signal.',
            q30: 'The Q30 is the most complete and performing product of the whole TiVuSat HD offer, from its completeness of numerous rear outputs including antenna loop, digital audio, scart, HDMI, lan, and 12 volts up to its unique peculiarity of its genre with the ability to also record. With its sober, compact and elegant design you can store it wherever you want.',
            q80: 'With this next generation S2/T2 decoder, you will be able to watch the free channels on the tivùsat platform in 4K Ultra HD and HD, all Free To Air satellite channels and the broadcasting with the new digital terrestrial DVB-T2 standard. ',
            q90: 'The COMBO 4K decoder is the latest generation of decoders, it allows the viewing of the free channels present on tivùsat in HD and 4K Ultra HD, of all the Free To Air satellite channels and of the digital terrestrial ones. Ideal solution for all those who want to enjoy the HD and UltraHD / 4K quality visible on tivùsat or access digital terrestrial. The Q90 also has many functions that make it unique on the market: VCR, Mediaplayer and interactive and on demand TV with HbbTV including RAI PLAY and MEDIASET PLAY (internet connection required).\n' +
                '\n' +
                'The power supply is 12V, also suitable for use on the move (boats and campers).',
            maximo: "Make the most of your TV with Digiquest Maximo, the latest generation S2/T2 COMBO decoder for viewing free and encrypted television programs from the tivùsat platform, all FreeToAir satellite channels and those of the new DVB-T2 digital terrestrial. Complete and easy to use decoder, thanks to the self-detection of the satellite system, the back-up function, the SOS button, the rapid tuning of the channels of the tivùsat package and the possibility of activating the recording function.\n" +
                "Tivùsat card included in the package.",
            ti9: 'For viewing free and coded television programs on the Tivùsat satellite platform and all free-to-air satellite channels (hotbird, astra, etc.). Ideal solution for all those who live in areas difficult to reach by the digital terrestrial television signal.',
            microsat: "Minimum dimensions, maximum performance. The only Tivùsat retro TV decoder: it can disappear behind your TV or be fixed on any type of surface thanks to the supplied removable support with adhesive and hole. \n" +
                "IR sensor with display that can show the time on standby if desired. \n" +
                "Easy to use, thanks to satellite system auto-detection, back-up function and SOS button. \n" +
                "Mediaplayer and 2in1 remote control that also allows you to control the main TV functions. \n" +
                "12V power supply allowing use on the move (campers, boats). Tivùsat SIMcard included in the package.\n",
        },
        macMenu: {
            step1: 'Open the Main Menu and select Installation',
            step2: 'In Installation select Software Information',
            step3: 'You should now see the MAC address and you can now enter this in the corresponding field at the bottom of the website.',
            pvrAlreadyPurchased: 'A PVR ticket has already been purchased for the entered MAC address. Do you want to generate a new code?',
            backToBoxSelection: 'back to box selection',
            invalidMac: 'The MAC Address is not complete',
            confirmMac: 'Confirm MAC Address',
            macDontMatch: 'The MAC addresses do not match',
            info: 'Info',
            generateKey: 'Generate key',
            q80Step1: 'Open the options menu.',
            q80Step2: 'Move to "Installation" and select "Software Information"',
            q80Step3: 'You should now see the MAC address and you can now enter this in the corresponding field at the bottom of the website.',
            ti9Step1: 'Open the settings menu and select application.',
            ti9Step2: 'Select network info.',
            ti9Step3: 'You should now see the MAC address and you can now enter this in the corresponding field at the bottom of the website.',
            enterMacWithSymbols: 'Enter the MAC address complete with symbols (: or -)',
        },
        orderOptions: {
            orderOptions: 'Order options',
            yourOrderOptions: 'Your order options',
            loginWithYourEmail: 'Login with your e-mail address',
            registerAsAnCustomer: 'Register as an customer',
            registerNow: 'Register now',
            continueAsGuest: 'Continue as a guest',
        },
        register: {
            'register': 'Register',
            billingAddress: 'Billing address',
            firstName: 'First name',
            lastName: 'Last name',
            street: 'Street, house no',
            postcode: 'Postcode',
            city: 'City',
            country: 'Country',
            confirmPassword: 'Confirm password',
            acceptConditions: 'Accept conditions',
            subscribeNewsletter: 'Subscribe for newsletter',
            passwordDontMatch: "Your passwords don't match",
        },
        overview: {
            boxType: 'Box type',
            amount: 'Amount',
            totalAmount: 'Total amount',
            withdrawlRight: 'Accept to lose withdrawal right',
            overview: 'Overview',
        },
        pvrTicket: {
            yourPvrTicket: 'This is your PVR-Ticket',
            sendMailWithInvoice: 'we also send a mail to you with the invoice and the pvr code',
            theCodeWillBeExpired: 'The code will be expired in three days',
            enterCodeInTheFollowingMenu: 'You can enter the code in the following menu',
            downloadInvoice: 'Click here to download the invoice',
        },
        footer: {
            registeredOffice: 'Registered office',
            assistanceAndSupport: 'Assistance and support',
            operationalHeadquarters: 'Operational headquarters',
            followUsOn: 'Follow us on',
            cookiePolicy: 'Cookie Policy ',
            privacyPolicy: 'Privacy Policy',
        },
        faq: {
            whatIsAPVRTicket: 'What is a PVR-Ticket',
            whatIsAPVRTicketText: 'A PVR ticket is a secret code that is generated for a specific STB only. Thus, each STB has its own code. Also the time when the key code is generated is taken into account. Therefore the PVR ticket that is generated for a given MAC address can only be used on a STB that has this unique MAC address. It does not work on other devices. The PVR ticket has to be inserted into the STB within a given time of maximal 4 days. Later on the PVR ticket is not valid any more.',
            whatDoesThePVRTicketDo: 'What does the PVR ticket do to my STB?',
            whatDoesThePVRTicketDoText: 'The PVR ticket is a secret code that applies only to your STB system and has to be entered in a specific menu of the STB to activate the PVR functionality. This PVR functionality allows you to do recordings on a connected USB storage device of suitable capacity and performance. The PVR ticket is bought on this web page and has to be inserted into the STB within 4 days after purchase.',
            insertTicketWithinThreeDays: 'What happens if I do not insert the PVR ticket within the given 3 days?',
            insertTicketWithinThreeDaysText: 'A PVR ticket that is not inserted within 3 days after purchase becomes invalid. Thus, the STB will not accept it anymore and therefore it is useless. So therefore make sure you enter it in time when it is purchased. If you didn\'t manage to enter the PVR code before it expires, you can generate a new code for free by entering the same mac address in the shop as before.',
            iHavePurchasedTicket: 'I have purchased a PVR ticket and want to enter it but the STB does not take it. What is wrong? ',
            iHavePurchasedTicketText: 'First you have to check if the purchase was done recently and the PVR ticket is no older than 4 days. If it is not then please also check if the STB is connected to a broadcast network and has a valid live TV signal so that the STB also receives a valid time from there. As the algorithms to activate the PVR gets the time from the PVR ticket and compares to its local time, the STB must have a valid time so that the STB does not think the 4 days have already passed by.',
            buyWrongTicket: 'What happens if I enter the wrong MAC address and buy a PVR ticket for this wrong MAC?',
            buyWrongTicketText: 'If you bought the PVR ticket for the wrong MAC address there is no way of get any reimbursement of the costs for this PVR ticket. Therefore please check carefully if the correct MAC address is entered.',
            pvrNotWork: 'I bought the PVR ticket and activated the PVR but my STB does not record anything. What is wrong?',
            pvrNotWorkText: 'You have to connect a USB storage device of suitable size (storage capacity) and read/write performance. Please make sure that the USB storage device is formatted with the methods integrated into the STB (if there are). In any case please check the detailed instructions of the STB for PVR usage.'
        },
        terms: {
            terms: 'TERMS & CONDITIONS',
            price: 'Price',
            priceText: 'This price list is subject to change without notice. The price agreed at the time of the order remains valid until the complete execution of the order.',
            priceText2: 'All prices are VAT included.',
            warranty: 'Warranty',
            warrantyText: 'All items are covered by warranty, damages caused by inappropriate use of the products purchased are excluded. The compositions may differ from what is shown in the photos, based on the availability of the moment upon notification to the customer.',
            withdrawal: 'Right of withdrawal',
            withdrawalText: 'Pursuant to Article 59 of the Consumer Code, the exercise of the right of withdrawal' +
                ' limited to the purchase of the PVR code through the site https://attivaregistrazione.digiquest.it is expressly' +
                ' excluded since it is digital content  which is' +
                ' not' +
                ' supplied on a tangible medium.\n',
            contactInfo: 'Contact info',
            contactInfoText: 'Digiquest Solutions surl\n' +
                'Via Avisio, 18\n' +
                '00048 Nettuno (RM)\n' +
                'Phone no.: +39 06 9857981\n' +
                'E-mail: info@digiquest.it\n' +
                'Web: www.digiquest.it/\n'
        },
        back: 'Back',
        cancel: 'Cancel',
        logInButton: 'Login',
        passwordForgotten: 'Have you forgotten your password?',
        logInErrorMsg: 'Please check if your email and password match',
        passwordResetMsg: 'An email has been sent to you to reset your password.',
        resetPassword: 'Reset password',
        pleaseEnterEmail: 'Please enter your e-mail!',
        pleaseEnterPassword: 'Please enter a password!',
        next: 'Next',
        message: {
            hello: 'hello world'
        },
        thisIsYourTicket: 'This is your PVR-Ticket',
        pvrText1: 'Open the settings menu and select USB.',
        pvrText2: 'Select PVR Activation',
        pvrText3: 'Enter the Key generated above. Your device does not need to be connected to the Internet. WARNING: you have to enter the PVR within 3 days of purchase!',
        pvrText4: 'The PVR feature is activated.',
        pvrText1Q80: 'Open the options menu.',
        pvrText2Q80: 'Move to "PVR" and Select "PVR Activation"',
        pvrText3Q80: 'Enter the Key generated above. Your device does not need to be connected to the Internet. WARNING: you have to enter the PVR within 3 days of purchase!',
        pvrText4Q80: 'The PVR feature is activated.',
        cookies: 'We use cookies on our website to offer you a more relevant experience by remembering your preferences and repeating visits. By clicking on \'ACCEPT\', you consent to the use of ALL cookies.',
        signUpSuccess: 'You have been successfully registered and a confirmation email has been sent to you. please confirm this to have access to your account',
        passwordChangeSuccess: 'Your password has been changed successfully',
        confirmSuccess: 'your email has been successfully confirmed',
        acceptCon: 'You need to accept the conditions',
        subscribeForNews: 'Subscribe for newsletter',
        emailTaken: 'The email is already taken',
        disclosure: 'Disclosure',
        iAccept: 'I Accept',
        accountDoesNotExist: 'Account does not exists',
        userDoesNotExists: 'User does not exist',
        userNotFound: 'User not found',
        changePassword: 'Change Password',
        currentPassword: 'Curretn password:',
        edit: 'Edit',
        pleaseEnterNewPas: 'Please enter a new password!',
        deleteAccount: 'Delete account',
        deleteQuestion: 'Are you sure you want to delete your account?',
        enterCurrentPas: 'Please enter your current password!',
        deleteSuccess: 'Your account has been successfully deleted',
        pasChangeSuccess: 'Your password has been changed successfully',
        save: 'Save',
        changesSaveSuc: 'Changes saved successfully',
    },
    it: {
        nav: {
            myAccount: 'AREA UTENTE',
            logout: 'LOG-OUT',
            boxTypes: 'TIPO DI STB',
            transactions: 'TRANSAZIONI',
            myOrders: 'I MIEI ORDINI',
            register: 'REGISTRATI',
            logIn: 'LOG-IN'
        },
        home: {
            getPvrKey: 'OTTIENI IL CODICE PVR',
            q20: 'Per la visione dei programmi televisivi gratuiti e codificati della piattaforma satellitare Tivùsat e di tutti i canali satellitari free to air (hotbird, astra, ecc.). Soluzione ideale per tutti coloro che risiedono in zone difficilmente raggiungibili dal segnale televisivo digitale terrestre.',
            q30: 'Il Q30 è il prodotto più completo e performante di tutta l’offerta TiVuSat HD, dalla sua completezza di numerose uscite posteriori tra cui loop antenna, audio digitale, scart, HDMI, lan, e 12 volt fino ad arrivare alla sua peculiarità unica del suo genere con la possibilità anche di registrare. Con il suo design sobrio compatto ed elegante potrai riporlo ovunque tu voglia.',
            q80: 'Decoder di ultima generazione S2/T2, permette la visione dei canali gratuiti presenti sulla piattaforma tivùsat in 4K Ultra HD\n' +
                'e HD, di tutti i canali satellitari Free To Air e di quelli del nuovo digitale terrestre DVB-T2.',
            maximo: "Sfrutta al massimo la tua TV  con Digiquest Maximo, decoder COMBO di ultima generazione S2/T2 per la visione dei programmi televisivi gratuiti e codificati della piattaforma tivùsat, di tutti i canali satellitari FreeToAir  e di quelli del nuovo digitale terrestre DVB-T2. Decoder completo e facile da usare, grazie all'auto-rilevamento dell'impianto satellitare, alla funzione back-up, al tasto SOS, alla sintonizzazione rapida dei canali del pacchetto tivùsat e alla possibilità di attivare la funzione di registrazione. \n" +
                "Tessera tivùsat inclusa nella confezione.",
            q90: 'Il decoder COMBO 4K è l’ultima generazione di decoder, permette la visione dei canali gratuiti presenti su tivùsat in HD e 4K Ultra HD, di tutti i canali satellitari Free To Air e di quelli digitali terrestri. Soluzione ideale per tutti coloro che vogliono godere della qualità HD e UltraHD/4K visibile su tivùsat o accedere al digitale terrestre.  Il Q90 inoltre ha molte funzioni che lo rendono unico sul mercato: Videoregistratore, Mediaplayer e la TV interattiva e on demand con l’HbbTV tra i quali RAI PLAY e MEDIASET PLAY (è necessaria la connessione a internet).\n' +
                '\n' +
                'L’alimentatore è 12V, adatto anche all’uso in movimento (barche e camper).',
            ti9: 'Per la visione dei programmi televisivi gratuiti e codificati della piattaforma satellitare Tivùsat e di tutti i canali satellitari free to air (hotbird, astra, ecc.). Soluzione ideale per tutti coloro che risiedono in zone difficilmente raggiungibili dal segnale televisivo digitale terrestre.',
            microsat: "Minime dimensioni, massime prestazioni. L'unico decoder tivùsat retro TV: può scomparire dietro al tuo TV o essere fissato su qualsiasi tipo di superficie grazie al supporto removibile in dotazione provvisto di adesivo e foro. \n" +
                "Sensore IR con display che, se si desidera, può visualizzare l'orario in standby. \n" +
                "Facile da usare, grazie all'auto-rilevamento dell'impianto satellitare, alla funzione back-up e al tasto SOS. \n" +
                "Mediaplayer e telecomando 2in1 che permette di poter controllare anche le principali funzioni della TV. \n" +
                "Alimentatore 12V che ne consente l'utilizzo anche in movimento (camper, barche). SIMcard tivùsat inclusa nella confezione.",
        },
        macMenu: {
            step1: 'Apri il Menu Principale e seleziona Installazione',
            step2: 'In Installazione scorri e seleziona Informazioni sul software',
            step3: 'Ora dovresti vedere l\'indirizzo MAC che potrai inserire nel campo in basso su questa pagina.',
            pvrAlreadyPurchased: 'È stato già acquistato un codice PVR per questo MAC address. Vuoi generarne uno nuovo?',
            backToBoxSelection: 'Torna alla selezione del STB',
            invalidMac: 'Il MAC Address non è completo',
            confirmMac: 'Conferma il MAC Address',
            macDontMatch: 'I MAC Address non corrispondono',
            info: 'Informazioni',
            generateKey: 'Genera codice',
            q80Step1: 'Apri il Menu Principale. ',
            q80Step2: 'Scorri su Installazione, accedi al sottomenu e seleziona Informazioni sul Software. ',
            q80Step3: 'Ora dovresti vedere il MAC address che potrai inserire nel campo in basso su questa pagina.',
            ti9Step1: 'Apri il Menu Principale e seleziona Applicazioni',
            ti9Step2: 'Scorri e seleziona Info di Rete',
            ti9Step3: 'Ora dovresti vedere il MAC address che potrai inserire nel campo in basso su questa pagina.',
            enterMacWithSymbols: 'Inserire l’indirizzo MAC completo di simboli (: o -)',
        },
        orderOptions: {
            orderOptions: 'Opzioni ordine',
            yourOrderOptions: 'Le tue opzioni ordine',
            loginWithYourEmail: 'Log-in con il tuo indirizzo e-mail',
            registerAsAnCustomer: 'Registrati come cliente',
            registerNow: 'Registrati ora',
            continueAsGuest: 'Continua come ospite',
        },
        register: {
            register: 'Registrati',
            billingAddress: 'Indirizzo di fatturazione',
            firstName: 'Nome',
            lastName: 'Cognome',
            street: 'Via e numero',
            postcode: 'C.A.P.',
            city: 'Città',
            country: 'Paese',
            confirmPassword: 'Conferma Password',
            acceptConditions: 'Accetta la condizioni di vendita',
            subscribeNewsletter: 'Iscriviti alla newsletter',
            passwordDontMatch: "La tua password non corrisponde",
        },
        overview: {
            overview: 'Riepilogo',
            boxType: 'Tipo di STB',
            amount: 'Importo',
            totalAmount: 'Importo totale',
            withdrawlRight: 'Accetta di rinunciare al diritto di recesso',
        },
        pvrTicket: {
            yourPvrTicket: 'Questo è il tuo codice PVR',
            sendMailWithInvoice: 'Ti è stata inviata anche una e-mail con la ricevuta e il tuo codice PVR',
            theCodeWillBeExpired: 'Il codice PVR scadrà tra 3 giorni',
            enterCodeInTheFollowingMenu: 'Puoi inserire il codice nel seguente menù',
            downloadInvoice: 'Clicca qui per scaricare la ricevuta',
        },
        footer: {
            registeredOffice: 'Sede legale',
            assistanceAndSupport: 'Assistenza e Supporto',
            operationalHeadquarters: 'Sede operativa',
            followUsOn: 'Seguici su',
            cookiePolicy: 'Cookie Policy ',
            privacyPolicy: 'Privacy Policy',
        },
        faq: {
            whatIsAPVRTicket: 'Che cos’è un codice PVR?',
            whatIsAPVRTicketText: 'Un codice PVR è un codice segreto generato per un solo STB specifico. Per questo, ogni STB ha un codice personalizzato. Viene presa in considerazione anche la data di generazione del codice.\n' +
                'Per questo il codice PVR generato per uno specifico MAC address potrà essere utilizzato su quell’unico MAC address. Non funzionerà su altri dispositivi.\n' +
                'Il codice PVR deve essere inserito nel STB entro un tempo massimo di 3 giorni. Passato questo tempo il codice non sarà più valido. ',
            whatDoesThePVRTicketDo: 'Cosa apporta al mio STB?',
            whatDoesThePVRTicketDoText: 'Il codice PVR è un codice segreto che si applica unicamente al tuo sistema STB. Per attivare la fun-zionalità PVR il codice deve essere inserito in un menù specifico del STB. \n' +
                'Questa funzionalità PVR ti permetterà di registrare su una memoria USB collegata al STB. La memoria USB deve avere capacità e prestazioni idonee. \n' +
                'È possible acquistare il codice PVR su questa piattaforma e deve essere inserito nel STB entro 3 giorni dall’acquisto.',
            insertTicketWithinThreeDays: 'Cosa succede se non inserisco il codice PVR entro 3 giorni dall’acquisto?',
            insertTicketWithinThreeDaysText: 'Un codice PVR non inserito entro 3 giorni dall’acquisto diventa invalido. Pertanto non verrà accettato dal STB e diventerà quindi inutilizzabile. Si raccomanda quindi d’inserire per tempo il codice PVR acquistato. Se il codice PVR non è stato inserito prima della scadenza è comunque possibile generare gratuitamente un nuovo codice reinserendo lo stesso indirizzo MAC nella pagina del prodotto su questa piattaforma. \n',
            iHavePurchasedTicket: 'Ho acquistato un codice PVR e voglio inserirlo nel STB, ma non viene accettato. Cosa posso fare?',
            iHavePurchasedTicketText: 'Prima di tutto bisogna verificare che l’acquisto sia stato fatto recentemente e che il codice PVR non sia più vecchio di 3 giorni. \n' +
                'In caso non lo sia, controllare che il STB sia connesso a una rete broadcast e che abbia un segnale TV che permetta al STB di ricevere una data valida. \n' +
                'L’algoritmo che attiva il PVR compara la data del codice PVR con la data locale, pertanto il STB deve avere una data valida per verificare che il codice non sia scaduto.',
            buyWrongTicket: 'Cosa succede se inserisco il MAC address sbagliato e compro il codice PVR per questo MAC address errato?',
            buyWrongTicketText: 'Se hai comprato il codice PVR inserendo un MAC address sbagliato non sarà possibile ottenere un rimborso del costo per questo codice PVR. \n' +
                'Si prega pertanto di controllare accuratamente il MAC address inserito in fase di acquisto.',
            pvrNotWork: 'Ho comprato un codice PVR e l’ho attivato, ma il STB non registra nulla. Cosa posso fare?',
            pvrNotWorkText: 'È necessario inserire un’unità di memoria USB di grandezza (capacità di memoria) idonea, e prestazioni di lettura/scrittura. \n' +
                'Controllare pertanto che la memoria USB sia formattata con i metodi integrati nel STB (se ve ne sono). In ogni caso, controllare le istruzioni dettagliate del STB riguardo l\'uso di memoria USB.'
        },
        terms: {
            terms: 'CONDIZIONI DI VENDITA',
            price: 'Prezzi ',
            priceText: 'Il Presente listino è soggetto a variazioni senza preavviso. Il prezzo concordato in fase d\'ordine resta valido fino alla completa esecuzione dello stesso.\n',
            priceText2: 'Tutti i prezzi si intendono I.V.A. inclusa.\n',
            warranty: 'Garanzia',
            warrantyText: 'Tutti gli articoli sono garantiti, sono esclusi i danni provocati da un uso non appropriato dei prodotti acquistati. Le composizioni potranno differire da quanto riportato nelle foto, in base alla disponibilità del momento previa comunicazione al cliente. ',
            withdrawal: 'Diritto di recesso',
            withdrawalText: "È espressamente escluso ai sensi dell’articolo 59 del Codice del Consumo, l'esercizio del diritto di recesso limitatamente all'acquisto codice PVR a mezzo del sito https://attivaregistrazione.digiquest.it trattandosi di un supporto non materiale con cui ottenere un contenuto digitale aggiuntivo.\n" +
                "\n",
            contactInfo: 'Informazioni di contatto',
            contactInfoText: 'Digiquest Solutions surl\n' +
                'Via Avisio, 18\n' +
                '00048 Nettuno (RM)\n' +
                'Tel.: +39 06 9857981\n' +
                'E-mail: info@digiquest.it\n' +
                'Web: www.digiquest.it/\n'
        },
        back: 'Indietro',
        cancel: 'Cancella',
        logInButton: 'Log-in',
        passwordForgotten: 'Hai dimenticato la tua password?',
        logInErrorMsg: 'Per favore, controlla che e-mail e password corrispondano',
        passwordResetMsg: "Ti è stata inviata un'e-mail per resettare la tua password",
        resetPassword: 'Resetta la password',
        pleaseEnterEmail: 'Inserisci il tuo indirizzo e-mail',
        pleaseEnterPassword: 'Inserisci la tua password',
        next: 'Avanti',
        thisIsYourTicket: 'Questo è il tuo codice PVR',
        pvrText1: 'Apri il Menu Principale e seleziona USB.',
        pvrText2: 'Scorri e seleziona Attivazione PVR',
        pvrText3: 'Inserisci il codice appena generato che trovi qui in alto. Non c\'è bisogno che il decoder sia connesso ad Internet. NB: il codice deve essere inserito entro 3 giorni dall\'acquisto!',
        pvrText4: 'La funzione PVR è ora attiva.',
        pvrText1Q80: 'Apri il Menu Principale.',
        pvrText2Q80: 'Scorri su PVR, accedi al sottomenu e seleziona Attivazione PVR.  ',
        pvrText3Q80: 'Inserisci il codice PVR generato all\'acquisto. NB: il codice deve essere inserito entro 3 giorni dall\'acquisto!',
        pvrText4Q80: 'The PVR feature is activated.',
        cookies: 'Utilizziamo i cookie sul nostro sito Web per offrirvi un\'esperienza più pertinente ricordando le tue preferenze e ripetendo le visite. Facendo clic su "ACCETTO", acconsenti all\'uso di TUTTI i cookie.',
        signUpSuccess: 'La registrazione è andata a buon fine e ti è stata inviata una e-mail di conferma. Per favore conferma il tuo indirizzo e-mail per accedere al tuo account.',
        passwordChangeSuccess: 'La tua password è stata modificata correttamente',
        confirmSuccess: 'La tua e-mail è stata verificata con successo',
        acceptCon: 'Accetta la condizioni di vendita',
        subscribeForNews: 'Iscriviti alla newsletter',
        emailTaken: 'Questa è e-mail è già stata registrata',
        disclosure: 'Informativa sui nostri Cookies',
        iAccept: 'Accetto',
        accountDoesNotExist: 'L\'account non esiste',
        userDoesNotExists: 'L\'utente non esiste',
        userNotFound: 'Utente non trovato',
        changePassword: 'Modifica password',
        currentPassword: 'Password attuale:',
        edit: 'Modifica',
        pleaseEnterNewPas: 'Per favore inserisci una nuova password!',
        deleteAccount: 'Elimina l\'account',
        deleteQuestion: 'Vuoi veramente eliminare il tuo account?',
        enterCurrentPas: 'Per favore inserisci la password attuale!',
        deleteSuccess: 'Il tuo account è stato eliminato con successo',
        pasChangeSuccess: 'La tua password è stata modificata correttamente',
        save: 'Salva',
        changesSaveSuc: 'Modifiche salvate correttamente',
    }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: 'en', // set locale
    messages, // set locale messages
})

Vue.config.productionTip = false


new Vue({
    router,
    store: store,
    i18n,
    render: h => h(App),
}).$mount('#app')

